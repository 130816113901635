.nav {
    padding: 40px 40px 0 40px;
    z-index: 2;
}

.nav-title {
    font-size: 1.8rem;
    color: var(--green-400);
}

.navbar-nav {
    margin-left: 140px;
    column-gap: 25px;
}

.nav-item {
    color: var(--dark-600);
}

.nav-item.active {
    color: var(--green-white-100);
    position: relative;
}

.nav-item.active::before {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    top: 50%;
    left: -30px;
    background: var(--green-400);
    border-radius: 5px;
    transform: rotate(45deg) translateY(-70%);
}

.nav-link {
    color: inherit;
}

.navbar-buttons {
    margin-left: auto;
    column-gap: 20px;
}

.navbar-responsive-menu {
    display: none !important;
}

@media screen and (max-width: 1100px) {
    .d-none-1100 {
        display: none;
    }

    .navbar-responsive-menu {
        display: block !important;
    }

    .navbar-nav {
        margin-left: 90px;
    }
}

@media screen and (max-width: 960px) {
    .navbar-nav {
        display: none !important;
    }
}