.dropdown {
    position: relative;
}

.dropdown button {
    border: 1px solid var(--green-400);
    column-gap: 10px;
}

.dropdown-menu {
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    width: 160px;
    outline: 1px solid var(--green-400);
    background-color: var(--dark-900);
    z-index: 10;
    border-radius: 10px;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    transition: .5s;
}

.dropdown-item-menu {
    padding: 15px;
    color: var(--white-100);
    transition: .4s;
    cursor: pointer;
}

.dropdown-item-menu.active {
    background-color: var(--green-400);
    color: var(--dark-900);
}

.dropdown-item-menu:hover {
    background-color: var(--white-100);
    color: var(--dark-900);
}

.dropdown:hover .dropdown-menu {
    top: 70px;
    visibility: visible;
    opacity: 1;
}

.dropdown-arrow-icon {
    transition: .6s;
}

.dropdown:hover .dropdown-arrow-icon {
    transform: rotate(180deg);
}