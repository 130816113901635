.my-masonry-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: -30px;
    width: auto;
}

.my-masonry-grid_column {
    padding-left: 30px;
}