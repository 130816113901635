.my-masonry {
    position: relative;
    margin-bottom: 30px;
    display: flex;
    border-radius: 10px;
    overflow: hidden;
}

.my-masnry-description {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 150px;
    background: linear-gradient(to top, rgb(9, 9, 10, 0.8), transparent 100%);
    align-items: flex-end;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: .7s;
}

.my-masnry-user-box {
    width: 100%;
    padding: 0 20px;
    margin-bottom: 20px;
    column-gap: 20px;
    margin-bottom: -80px;
    transition: .5s;
}

.my-masnry-user-prof {
    width: 45px;
    height: 45px;
}

.my-masnry-user-prof img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.my-masnry-user-prof-desc {
    row-gap: 10px;
    font-size: 0.8rem;
    text-transform: capitalize;
}

.my-masnry-user-prof-desc h1 {
    color: var(--green-400);
}

.my-masnry-user-prof-desc h3 {
    color: var(--dark-600);
}

.my-masonry:hover .my-masnry-description {
    visibility: visible;
    opacity: 1;
}

.my-masonry:hover .my-masnry-user-box {
    margin-bottom: 15px;
}